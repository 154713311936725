<template>
  <div class="chart-container" style="height: auto">
    <p class="d-none">{{ chartData.categories }} {{ chartData.data }}</p>
    <column-chart ref="columnChart" :options="chartOptions"></column-chart>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  name: "ColumnChart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    disableDataLabel: {
      type: Boolean,
      default: true,
    },
    annotation: {
      type: String,
      default: "",
    },
  },
  components: {
    columnChart: Chart,
  },
  created() {
    this.chartOptions.colors = this.chartData.color;
    this.chartOptions.series = this.chartData.data;
    if (
      this.chartData.plotOptions.series !== undefined &&
      this.chartData.plotOptions.series.cursor !== undefined
    ) {
      this.chartOptions.plotOptions.series.cursor = this.chartData.plotOptions.series.cursor;
    }
    if (
      this.chartData.plotOptions.series !== undefined &&
      this.chartData.plotOptions.series.point !== undefined
    ) {
      this.chartOptions.plotOptions.series.point = this.chartData.plotOptions.series.point;
    }
  },
  beforeUpdate() {
    this.chartOptions.series = this.chartData.data;
    this.chartOptions.plotOptions.series.dataLabels.annotation = this.annotation;
    this.chartOptions.plotOptions.series.dataLabels.enabled = this.disableDataLabel;
    if (
      this.chartData.plotOptions.series !== undefined &&
      this.chartData.plotOptions.series.cursor !== undefined
    ) {
      this.chartOptions.plotOptions.series.cursor = this.chartData.plotOptions.series.cursor;
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          width: this.chartData.width,
          height: this.chartData.height,
          scrollablePlotArea: {
            minWidth: this.chartData.scrollableWidth
              ? this.chartData.scrollableWidth
              : 0,
            opacity: 1,
          },
        },
        title: this.chartData.title,
        subtitle: this.chartData.subtitle,
        xAxis: this.chartData.xAxis,
        yAxis: this.chartData.yAxis,
        tooltip: this.chartData.tooltip,
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: this.chartData.plotOptions.column,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            cursor: "",
            point: {},
            dataLabels: {
              enabled: this.disableDataLabel,
              annotation: this.annotation,
              formatter: function(data) {
                if (this.y > 999) {
                  return (
                    abbreviateNumber(this.y, 1, {
                      symbols: ["", "k", "M", "B", "T", "P", "E"],
                    }) + data.annotation
                  );
                } else {
                  return Math.round(this.y * 10) / 10 + data.annotation;
                }
              },
            },
          },
        },
        // this.chartData.plotOptions,
        colors: this.chartData.color,
        series: this.chartData.data,
        legend: this.chartData.legend,
      },
    };
  },

  mounted() {
    this.chartOptions.series = this.chartData.data;
    if (this.chartOptions.xAxis.crosshair.events === undefined) {
      this.chartOptions.xAxis.crosshair = {};
      this.chartOptions.xAxis.crosshair.enabled = false;
    }
    const theChart = this.$refs.columnChart;
    this.chartOptions.tooltip = {
      shared: true,
      formatter: function(tooltip) {
        theChart.columnIndex = theChart.options.xAxis.categories.indexOf(
          this.x
        );
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    };
    var self = this;
    this.chartOptions.xAxis.crosshair.events = {
      click: function() {
        const category =
          theChart.options.xAxis.categories[theChart.columnIndex];
        self.$emit("columnClick", category);
      },
    };
  },
};
</script>

<style lang="css">
.chart-container {
  max-height: 588px;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 0 0 30px 0; */
  background: #fff;
}

.highcharts-tooltip > span {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px !important;
  float: left;
  justify-content: flex-start;
}
.highcharts-markers {
  display: none;
}

/* .highcharts-root {
  padding: 0 20px 0px 10px;
} */

.make-bold {
  font-weight: 900;
}
</style>
