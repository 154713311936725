<template>
  <div class="chart-container">
    <p style="display: none;">{{ series }}</p>
    <x-range-chart :options="chartOptions"></x-range-chart>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import xRangeChart from "highcharts/modules/xrange";

xRangeChart(Highcharts);
export default {
  name: "XRangeChart",
  components: {
    xRangeChart: Chart,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#ff5252",
          "#1e88e5",
          "#5e35b1",
          "#00897b",
        ],
        chart: {
          type: "xrange",
          height: 300,
        },
        title: {
          text: "",
        },
        // xAxis: {
        //   type: "datetime",
        //   gridLineWidth: 1,
        // },
        xAxis: [
          {
            gridLineWidth: 0,
            labels: {
              enabled: false,
            },
            tickWidth: 0,
          },
          {
            min: 0,
            max: 100,
            // type: "datetime",
            title: {
              text: null,
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            gridLineWidth: 1,
            tickWidth: 0,
            labels: {
              // overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
            lineColor: "transparent",
          },
        ],
        yAxis: {
          title: {
            text: "",
          },
          enabled: false,
          categories: [],
          reversed: true,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadius: 10,
          },
        },
        legend: {
          align: "left",
          itemStyle: {
            color: "#8394b4",
          },
        },
        series: [],
        tooltip: {
          pointFormat: "{series.name}</b>",
        },
        credits: {
          enabled: false,
        },
      },
    };
  },
  beforeUpdate() {
    this.chartOptions.series = this.series;
  },
  mounted() {
    this.chartOptions.series = this.series;
  },
};
</script>

<style scoped>
>>> .highcharts-legend-item.highcharts-series-7 .highcharts-point {
  fill: #ffd740 !important;
}
>>> .highcharts-legend-item.highcharts-series-0 .highcharts-point {
  fill: #18ffff !important;
}
>>> .highcharts-legend-item.highcharts-series-1 .highcharts-point {
  fill: #69f0ae !important;
}
>>> .highcharts-legend-item.highcharts-series-2 .highcharts-point {
  fill: #ff9100 !important;
}
>>> .highcharts-legend-item.highcharts-series-3 .highcharts-point {
  fill: #ff5252 !important;
}
>>> .highcharts-legend-item.highcharts-series-4 .highcharts-point {
  fill: #1e88e5 !important;
}
>>> .highcharts-legend-item.highcharts-series-5 .highcharts-point {
  fill: #5e35b1 !important;
}
>>> .highcharts-legend-item.highcharts-series-6 .highcharts-point {
  fill: #00897b !important;
}
</style>
