<template>
  <div class="chart-container">
    <div class="d-flex justify-content-between">
      <div v-if="title" class="d-flex align-items-center heading">
        <div
          v-if="dot"
          :style="{ 'background-color': dot }"
          class="dot mr-4"
        ></div>
        {{ title }}
      </div>
      <span class="center-text" v-if="total !== ''">Total: {{ total }}</span>
      <button
        class="breakdown-btn"
        v-if="showBreakdown"
        @click="togglebreakdown"
      >
        <img
          v-if="!breakdown"
          class="eye-line"
          src="@/assets/eye-close-line.svg"
          alt=""
        />
        <img v-else class="eye-line" src="@/assets/eye-line.png" alt="" /><span
          class="breakdown-text"
          >Breakdown</span
        >
      </button>
    </div>
    <div class="recomended_content mt-4" v-if="subtitle !== ''">
      {{ subtitle }}
    </div>
    <div
      class="chart content-center"
      :class="{ 'my-5': !showLegend }"
      v-if="widthBy"
    >
      <div
        style="cursor: pointer;"
        v-for="(data, index) in chartData"
        :key="index"
        :style="{ width: `${data.percentage}` }"
        class="item"
        @click="clickedStripChart(data)"
        :class="{ active: activePart === data.label }"
      >
        <div v-if="!showLegend" class="item-title px-2">
          {{ data.label }}
        </div>
        <div
          class="item-bar"
          :style="{ background: data.backgroundColor }"
          style="padding: 0px 5px;"
        >
          <div class="item-label">
            {{ orgnizeNumber(data.value) }}
          </div>
          <div class="item-label">
            {{ data.symbol }}
          </div>
        </div>
      </div>
    </div>
    <div class="chart content-center" :class="{ 'my-5': !showLegend }" v-else>
      <div
        style="cursor: pointer;"
        v-for="(data, index) in chartData"
        :key="index"
        :style="data.value < 5 ? 'width: auto' : 'width:' + data.value/0.3 + '%'"
        class="item"
        @click="clickedStripChart(data)"
        :class="{ active: activePart === data.label }"
      >
        <div v-if="!showLegend" class="item-title px-2">
          {{ data.label }}
        </div>
        <div
          class="item-bar"
          :style="{ background: data.backgroundColor }"
          style="padding: 0px 5px;"
        >
          <div class="item-label">
            {{ orgnizeNumber(data.value) }}
          </div>
          <div class="item-label">
            {{ data.symbol }}
          </div>
        </div>
      </div>
    </div>
    <b-row v-if="showLegend" class="my-5">
      <b-col
        cols="6"
        v-for="(data, index) in chartData"
        :key="index"
        class="d-flex align-items-center"
      >
        <div
          class="legend-icon"
          :style="{ background: data.backgroundColor }"
        ></div>
        <div
          class="legend-label"
          :style="
            activePart === data.label
              ? 'font-size: large;'
              : 'font-size: medium;'
          "
          @click="clickedStripChart(data)"
        >
          {{ data.label }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  name: "Stripchart",
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    widthBy: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showBreakdown: {
      type: Boolean,
      default: false,
    },
    breakdown: {
      type: Boolean,
      default: false,
    },
    total: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    activePart: {
      type: String,
      default: "",
    },
    dot: {
      type: String,
      default: "",
    },
  },
  methods: {
    togglebreakdown() {
      this.clickedStripChart(this.chartData[0]);
      this.$emit("getBreakdown", !this.breakdown);
    },
    clickedStripChart(data) {
      this.$emit("getDataBreakdown", { data: data, breakdown: true });
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 1000) {
          return abbreviateNumber(data, 1, {
            symbols: ["", "k", "M", "B", "T", "P", "E"],
          });
        } else {
          return data;
        }
      };
    },
  },
};
</script>

<style scoped>
.item.active .item-bar {
  height: 5.5rem !important;
}
.chart-container {
  position: relative;
}
.chart {
  margin-top: 20px;
  display: flex;
}
.item-title {
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(5, 5, 5);
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap;
}
.item-bar {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 3px;
}
.item:first-child > .item-bar {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.item:last-child > .item-bar {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.item-label {
  color: rgb(5, 5, 5);
  font-family: ProximaNovaBold;
  font-weight: bold;
  overflow: hidden;
  font-size: small;
  display: flex;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.heading {
  font-size: 18px;
  font-weight: bold;
  /* margin-bottom: 40px; */
  margin-top: 16px;
}
.total {
  margin-top: 6px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #727988;
}
.breakdown-btn {
  width: 136px;
  height: 40px;
  background: #f3f3f3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: hidden;
}
.breakdown-text {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
}
.eye-line {
  width: 16px;
}
.center-text {
  text-align: center;
  top: 0px;
  position: absolute;
  width: 100%;
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;
}
.recomended_content {
  font-family: ProximaNovaRegular;
  font-size: 20px;
  color: #8394b4;
}
.legend-icon {
  width: 16px;
  height: 16px;
  background-color: blueviolet;
  border-radius: 50px;
  margin-right: 12px;
}
.legend-label {
  cursor: pointer;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: rgb(5, 5, 5);
}
.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
</style>
